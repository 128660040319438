#html5-qrcode-anchor-scan-type-change {
  display: none !important;
}

#html5qr-code-full-region > div > img {
  display: none;
}

#html5qr-code-full-region__scan_region > img {
  display: block !important;
  margin: 0 auto;
}

#html5qr-code-full-region__header_message {
  display: none !important;
}
